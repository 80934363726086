
import { Component, Prop, Ref, Mixins } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import ServiceSelect from "@/components/Service/Select.vue";
import { EstimateRequest } from "../../models";
import EstimateRequestMixin, {
  EstimateRequestTags,
} from "@/mixins/http/EstimateRequestMixin";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { EstimateRequestPayload } from "../../models/EstimateRequest";
@Component({
  components: {
    VModal,
    VFileUploader,
    ServiceSelect,
    DocumentForm,
  },
  directives: {
    WithErrors,
  },
})
export default class EstimateRequestCreateModal extends Mixins(
  EstimateRequestMixin
) {
  @RegisterHttp(EstimateRequestTags.EstimateRequestCreate)
  rejectRequest!: RequestData;

  @Prop({ required: true }) readonly realmId!: string;
  @Ref() readonly modal!: VModal;
  private estimateRequest: EstimateRequestPayload | null = null;

  open(estimateRequest: EstimateRequestPayload) {
    this.estimateRequest = estimateRequest;
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  onCreated(estimateRequest: EstimateRequest) {
    this.$emit("created", estimateRequest);
    this.reset();
    this.hide();
  }

  reset() {
    this.estimateRequest.message = null;
    this.estimateRequest.name = null;
    this.estimateRequest.serviceId = null;
  }

  async create() {
    const [data, errors] = await safeAsync(
      this.createEstimateRequest(this.estimateRequest)
    );
    if (!errors) {
      this.onCreated(data);
      showToast("Richiesta di stima creata");
    } else {
      showToast("Non è stato creare lòa richiesta di stima", {
        type: "error",
      });
    }
  }

  get loading() {
    return this.rejectRequest?.loading;
  }

  get httpTag() {
    return EstimateRequestTags.EstimateRequestCreate;
  }

  get title() {
    return "Invia richiesta di stima";
  }
}
