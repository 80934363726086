
import { Component, Ref, Mixins } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { EstimateRequest } from "../../models";
import EstimateRequestAdminMixin, {
  EstimateRequestAdminTags,
} from "@/mixins/http/admin/EstimateRequestAdminMixin";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
@Component({
  components: {
    VModal,
    VFileUploader,
    DocumentForm,
  },
  directives: {
    WithErrors,
  },
})
export default class EstimateRequestEstimateModal extends Mixins(
  EstimateRequestAdminMixin
) {
  @RegisterHttp(EstimateRequestAdminTags.EstimateRequestEstimate)
  rejectRequest!: RequestData;

  private file: any = null;

  @Ref() readonly modal!: VModal;
  private estimateRequest: EstimateRequest | null = null;

  open(estimateRequest: EstimateRequest) {
    this.estimateRequest = Object.assign(
      {},
      estimateRequest
    ) as EstimateRequest;
    (this.modal as any).show();
  }

  get fileName() {
    return this.file ? this.file.name : "";
  }

  hide() {
    (this.modal as any).hide();
  }

  onEstimated(estimateRequest: EstimateRequest) {
    this.$emit("estimated", estimateRequest);
    this.hide();
  }

  async estimate() {
    const form = new FormData();

    form.append("id", this.estimateRequest.id);
    form.append("answer", this.estimateRequest.answer);
    form.append("estimatedAt", this.estimateRequest.estimatedAt?.toString());
    if (this.file) {
      form.append("estimate", this.file.file);
    }

    const [data, errors] = await safeAsync(
      this.estimateEstimateRequest(this.estimateRequest?.id, form)
    );
    if (!errors) {
      this.onEstimated(data);
      showToast("Richiesta stimata");
    } else {
      showToast("Non è stato possibile stimare la richiesta", {
        type: "error",
      });
    }
  }

  get loading() {
    return this.rejectRequest?.loading;
  }

  get httpTag() {
    return EstimateRequestAdminTags.EstimateRequestEstimate;
  }

  get title() {
    return "Accetta richiesta di stima";
  }
}
