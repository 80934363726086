
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import moment from "moment";
import { EstimateRequest } from "../../models";
import EstimateRequestMixin, {
  EstimateRequestTags,
} from "@/mixins/http/EstimateRequestMixin";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import VAccordion from "@/components/Common/VAccordion.vue";
import EstimateModal from "@/components/EstimateRequest/EstimateModal.vue";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import { currentUserStore } from "../../store/typed";

@Component({
  components: {
    VAccordion,
    VFileUploader,
    EstimateModal,
  },
})
export default class EstimateRequestItem extends Mixins(EstimateRequestMixin) {
  @RegisterHttp(EstimateRequestTags.EstimateRequestAccept)
  readonly acceptRequest!: RequestData;

  @RegisterHttp(EstimateRequestTags.EstimateRequestReject)
  readonly rejectRequest!: RequestData;

  @Prop({ required: true }) readonly value!: EstimateRequest;

  @Watch("value")
  onValueChanged(value: EstimateRequest) {
    this.item = value;
  }

  private item = this.value || null;

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get estimateModalRef() {
    return `estimate_${this.item?.id}`;
  }

  get accepting() {
    return this.acceptRequest?.loading;
  }

  get rejecting() {
    return this.rejectRequest?.loading;
  }

  get isDraft() {
    return this.item?.status === "draft";
  }

  get isEstimated() {
    return this.item?.status === "estimated";
  }

  get showOrganizationName() {
    return this.isAdministrative && !this.item.initiativeId;
  }

  get organizationName() {
    return this.item?.organization?.name;
  }

  get statusLabel() {
    return this.item.statusLabel;
  }

  get code() {
    return `#${this.item?.id}`;
  }

  get isOrganizationUser() {
    return currentUserStore.currentUser?.isOrganizationUser;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  async accept() {
    const [data, errors] = await safeAsync(
      this.acceptEstimateRequest(this.item?.id)
    );
    if (!errors) {
      this.item = data;
      this.onAccepted(data);
      showToast("Richiesta accettata");
    } else {
      showToast("Non è stato possibile accettare la richiesta", {
        type: "error",
      });
    }
  }

  async reject() {
    const [data, errors] = await safeAsync(
      this.rejectEstimateRequest(this.item?.id)
    );
    if (!errors) {
      this.item = data;
      this.onRejected(data);
      showToast("Richiesta rifiutata");
    } else {
      showToast("Non è stato possibile rifiutare la richiesta", {
        type: "error",
      });
    }
  }

  onRejected(estimateRequest: EstimateRequest) {
    this.$emit("rejected", estimateRequest);
  }

  onAccepted(estimateRequest: EstimateRequest) {
    this.$emit("accepted", estimateRequest);
  }

  openEstimateModal() {
    (this.$refs[this.estimateModalRef] as any).open(this.item);
  }

  get itemStatusClass(): string {
    return `badge-${this.item.statusClass}`;
  }
}
