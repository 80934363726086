
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import EstimateRequestsList from "@/components/EstimateRequest/List.vue";

@Component({
  components: {
    PageSection,
    EstimateRequestsList,
  },
})
export default class EstimateRequestListContainer extends Vue {
  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }
}
