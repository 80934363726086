import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { EstimateRequest } from "@/models";
import * as qs from "qs";
import { EstimateRequestPayload } from "@/models/EstimateRequest";

export enum EstimateRequestTags {
  EstimateRequestIndex = "EstimateRequestIndex",
  EstimateRequestCreate = "EstimateRequestCreate",
  EstimateRequestAccept = "EstimateRequestAccept",
  EstimateRequestReject = "EstimateRequestReject",
}

@Component
export default class EstimateRequestMixin extends Vue {
  getEstimateRequests(data: any): Promise<EstimateRequest[]> {
    const url = `${SD_BASE_API_URL}/estimate_requests`;

    return httpStore.request({
      tag: EstimateRequestTags.EstimateRequestIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<EstimateRequest[]>;
  }

  createEstimateRequest(
    data: EstimateRequestPayload
  ): Promise<EstimateRequest> {
    const url = `${SD_BASE_API_URL}/estimate_requests`;

    return httpStore.request({
      tag: EstimateRequestTags.EstimateRequestCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<EstimateRequest>;
  }

  acceptEstimateRequest(id: string): Promise<EstimateRequest> {
    const url = `${SD_BASE_API_URL}/estimate_requests/${id}/accept`;

    return httpStore.request({
      tag: EstimateRequestTags.EstimateRequestAccept,
      url: url,
      method: "PUT",
      data: {},
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<EstimateRequest>;
  }

  rejectEstimateRequest(id: any): Promise<EstimateRequest> {
    const url = `${SD_BASE_API_URL}/estimate_requests/${id}/reject`;

    return httpStore.request({
      tag: EstimateRequestTags.EstimateRequestReject,
      url: url,
      method: "PUT",
      data: {},
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<EstimateRequest>;
  }
}
