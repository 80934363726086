
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { EstimateRequest } from "@/models";
import VPaginator from "@/components/Common/VPaginator.vue";
import EstimateRequestAdminMixin, {
  EstimateRequestAdminTags,
} from "@/mixins/http/admin/EstimateRequestAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import CreateModal from "@/components/EstimateRequest/CreateModal.vue";
import { currentUserStore } from "@/store/typed";
import RequestData from "@/store/types/RequestData";
import EmptyView from "@/components/Common/EmptyView.vue";
import EstimateRequestItem from "@/components/EstimateRequest/Item.vue";
import { EstimateRequestPayload } from "../../models/EstimateRequest";
import { Contract } from "../../models";
const indexTag = EstimateRequestAdminTags.EstimateRequestIndex;

@Component({
  components: {
    PageSection,
    CreateModal,
    EstimateRequestItem,
    VPaginator,
    EmptyView,
  },
})
export default class EstimateRequestList extends Mixins(
  EstimateRequestAdminMixin
) {
  @RegisterHttp(indexTag) readonly indexRequest!: RequestData;
  @Prop() readonly filter?: any;
  @Prop({ required: false }) readonly contract?: Contract;

  private items: EstimateRequest[] = [];

  private error = false;

  private page = 0;

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  @Ref() createEstimateRequestModal: any;

  get isOrganizationUser() {
    return currentUserStore.currentUser?.isOrganizationUser;
  }

  get organization() {
    return currentUserStore.currentUser?.organization;
  }

  get realmId() {
    return currentUserStore.currentUser?.organization?.realmId;
  }

  get defaultValue(): EstimateRequestPayload {
    return {
      organizationId: this.organization.id,
      initiativeId: this.contract?.initiative?.id,
    };
  }

  onCreated(estimateRequest: EstimateRequest) {
    this.items.unshift(estimateRequest);
  }

  openCreateModal() {
    (this.$refs["createEstimateRequestModal"] as any).open(this.defaultValue);
  }

  mounted() {
    this.load();
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  get payload() {
    return {
      page: this.page,
      filter: this.filter,
    };
  }

  async load() {
    const [data, errors] = await safeAsync(
      this.getEstimateRequests(this.payload)
    );

    if (!errors) {
      this.items = data;
      this.error = false;
    } else {
      this.error = true;
    }
  }
}
