import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_ADMIN_API_URL, SD_BASE_API_URL } from "@/utils/Constants";
import { EstimateRequest } from "@/models";
import * as qs from "qs";

export enum EstimateRequestAdminTags {
  EstimateRequestEstimate = "EstimateRequestEstimate",
  EstimateRequestIndex = "EstimateRequestIndex",
}

@Component
export default class EstimateRequestAdminMixin extends Vue {
  estimateEstimateRequest(id: string, data: any): Promise<EstimateRequest> {
    const url = `${SD_BASE_ADMIN_API_URL}/estimate_requests/${id}/estimate`;

    return httpStore.request({
      tag: EstimateRequestAdminTags.EstimateRequestEstimate,
      url: url,
      method: "PUT",
      data: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<EstimateRequest>;
  }

  getEstimateRequests(data: any): Promise<EstimateRequest[]> {
    const url = `${SD_BASE_API_URL}/estimate_requests`;

    return httpStore.request({
      tag: EstimateRequestAdminTags.EstimateRequestIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<EstimateRequest[]>;
  }
}
