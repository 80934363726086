import Service from "@/models/Service";
import { httpStore } from "@/store/typed";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
export enum ServiceTags {
  ServiceShow = "ServiceShow",
  ServiceIndex = "ServiceIndex",
}
@Component
export default class ServiceMixin extends Vue {
  getServices(data: any): Promise<Service[]> {
    const url = `${SD_BASE_API_URL}/services`;

    return httpStore.request({
      tag: ServiceTags.ServiceIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Service[]>;
  }

  getService(id: string | number): Promise<Service> {
    const url = `${SD_BASE_API_URL}/services/${id}`;

    return httpStore.request({
      tag: ServiceTags.ServiceShow,
      url: url,
      method: "GET",
    }) as Promise<Service>;
  }
}
